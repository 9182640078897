@import url('https://fonts.googleapis.com/css?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
    font-family: 'Rubik', sans-serif;
}

.main-nav {
    left: 0;
    right: auto;
}

.grid-box {
    max-width: 900px !important;
    padding: 0px !important;
}
/* 
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    min-height: 32px;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
} */
